import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Illustrations from "./components/Illustrations";
import Generative from "./components/Generative";
import Murals from "./components/Murals";
import Projects from "./components/Projects";


const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="container">
          {" "}
          {/* Adiciona o container aqui */}
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/generative" element={<Generative />} />
              <Route path="/murals" element={<Murals />} />
              <Route path="/illustrations" element={<Illustrations />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
