import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "./Carousel.css";

import image1 from "../assets/images/image-00001.jpg"; // Ajuste o caminho conforme necessário
import image2 from "../assets/images/image-00002.jpg";
import image3 from "../assets/images/image-00003.jpg";
import image4 from "../assets/images/image-00001.jpg"; 
import image5 from "../assets/images/image-00002.jpg";
import image6 from "../assets/images/image-00003.jpg";

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null); // Cria uma referência para o slider
  
  const images = [image1, image2, image3, image4, image5, image6];
  const settings = {
    dots: false, // Desativa os pontos padrão
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    fade: true, // Adiciona um efeito de transição suave
    cssEase: "linear", // Suaviza a transição
    beforeChange: (current, next) => setCurrentIndex(next), // Atualiza o índice antes da mudança
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index); // Move o slider para o índice correspondente
  };

  return (
    <div className="carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div className="carousel-slide" key={index}>
            <img src={image} alt={`Imagem ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className={`thumbnail ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleThumbnailClick(index)} // Chama a função ao clicar no thumbnail
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
