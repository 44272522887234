import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <section className="services-section">
      <h2>Nossos Serviços</h2>
      <p>
        "A Az Studios oferece uma ampla gama de serviços que combinam arte,
        tecnologia e criatividade. Nossa especialidade vai além da entrega de
        projetos artísticos: oferecemos soluções visuais que impactam, como murais Monumentais e ilustrações personalizadas que dão vida a
        ideias únicas.
      </p>
    </section>
  );
};

export default Services;
