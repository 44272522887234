import React from "react";
import "./About.css";

const About = () => {
  return (
    <section className="about-section">
      <h2>Sobre Nós</h2>
      <p>
        A Az Studios é uma casa criativa onde quebrar paradigmas e desafiar
        expectativas são parte do nosso DNA. Somos especialistas em arte que
        transcende o comum, trabalhando com uma equipe apaixonada e comprometida
        com a inovação. Nossa missão é deixar uma marca inesquecível.
      </p>
    </section>
  );
};

export default About;
