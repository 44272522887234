import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import "./Home.css";
import AOS from "aos"; // Importa AOS
import "aos/dist/aos.css"; // Importa os estilos do AOS

const Home = () => {
  useEffect(() => {
    AOS.init(); // Inicializa AOS
  }, []);

  return (
    <section className="home-section container">
      <div className="home-card ">
        <Carousel />
        <div>
          <h2 data-aos="fade-up" data-aos-duration="1000">Bem-vindo ao Az Studios</h2>
          <p data-aos="fade-up" data-aos-duration="1000">Transformamos suas ideias em realidade!</p>
        </div>
      </div>

      {/* Somente os cards abaixo têm o efeito AOS */}
      <div className="home-extra-cards">
        <Link to="/murals" className="card murals" data-aos="fade-up" data-aos-duration="1000">
          <div className="card-text">Murals</div>
        </Link>
        <Link
          to="/illustrations"
          className="card illustrations" data-aos="fade-up" data-aos-duration="1000"
        >
          <div className="card-text">Illustrations</div>
        </Link>
        <Link to="/generative" className="card generative" data-aos="fade-up" data-aos-duration="1000">
          <div className="card-text">Generative Art</div>
        </Link>
        <Link to="/projects" className="card projects" data-aos="fade-up" data-aos-duration="1000">
          <div className="card-text">Projects</div>
        </Link>
      </div>

      {/* Removido o AOS do botão */}
      <div className="home-contact-button">
        <Link to="/contact" className="contact-btn">
          Entre em Contato
        </Link>
      </div>
    </section>
  );
};

export default Home;
