import React from "react";
import "./Portfolio.css";

const Portfolio = () => {
  return (
    <section className="portfolio-section">
      <h2>Portfólio</h2>
      <p>
        Nossa arte fala mais alto do que qualquer palavra. Na Az Studios, cada
        projeto é uma oportunidade de explorar novos limites criativos. Confira
        alguns dos trabalhos que realizamos para empresas e particulares
      </p>
    </section>
  );
};

export default Portfolio;
