import React, { useEffect } from "react";
import { Link } from "react-router-dom"; // Usar o Link para o botão de contato
import AOS from 'aos'; // Importa AOS
import 'aos/dist/aos.css'; // Importa os estilos do AOS
import logo from '../assets/images/logo.png'; // Importe sua imagem aqui
import "./Header.css";

const Header = () => {
  useEffect(() => {
    AOS.init(); // Inicializa AOS
  }, []);

  return (
    <header>
      <div className="container" data-aos="fade-down" data-aos-duration="1000">
        <h1 className="header-title">
          <Link to="/" className="az-studios">
            <img src={logo} alt="AZ Studios Logo" className="logo" /> {/* Use a tag <img> */}
          </Link>
        </h1>
        <div className="header-contact">
          <Link to="/contact" className="contact-btn">
            Contato
          </Link>
        </div>
      </div>
     
    </header>
  );
};

export default Header;
