import React from "react";
import "./Murals.css";

const Murals = () => {
  return (
    <section className="murals-section">
      <h2>Grafites e Murais</h2>
      <p>
        Transformamos espaços públicos e privados com arte
        de alto impacto visual.
      </p>
    </section>
  );
};

export default Murals;
