import React from "react";
import "./Illustrations.css";

const Illustrations = () => {
  return (
    <section className="illustrations-section">
      <h2>Artes e Illustrações</h2>
      <p>
        Ilustrações e obras-de-artes personalizadas feitas a mão que dão vida a
        ideias únicas.
      </p>
    </section>
  );
};

export default Illustrations;
