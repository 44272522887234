import React from "react";
import "./Projects.css";

const Projects = () => {
  return (
    <section className="projects-section">
      <h2>Projetos Culturais</h2>
      <p>
        Projetos ........
      </p>
    </section>
  );
};

export default Projects;
