import React from "react";
import "./Generative.css";

const Generative = () => {
  return (
    <section className="generative-section">
      <h2>Arte Generativa</h2>
      <p>
        Ajudamos empresas a expandirem suas operações criativas com o uso de
        inteligência artificial.
      </p>
    </section>
  );
};

export default Generative;
